<script setup>
import { computed } from "vue";

import Spot from "@components/Spot/Spot.vue";
import SVGIcon from "@components/SVGIcon.vue";

const props = defineProps({
  size: { type: String, default: "default" },
  separated: { type: Boolean, default: false },
  title: { type: String, default: "" },
  image: { type: String, default: "" },
  alt: { type: String, default: "" },
  buttonText: { type: String, default: "" },
  buttonLink: { type: String, default: "" },
  theme: { type: String, default: "" },
  spotTemplates: { type: String, default: "" },
  headerSpotsMobile: { type: String, default: "" },
  headerSpotsDesktop: { type: String, default: "" },
  titleBig: { type: Boolean, default: false },
  fullWidth: { type: Boolean, default: false },
  sticky: { type: Boolean, default: true },
});

const additionalGap = computed(() => {
  switch (props.size) {
    case "narrow":
      return 240;
    case "form":
      return 286;

    default:
      return 0;
  }
});
</script>

<template>
  <section
    class="block"
    :class="[
      size,
      {
        separated,
      },
    ]"
  >
    <template v-if="separated">
      <div
        v-if="image"
        class="block-image-wrapper hidden-tablet hidden-desktop"
      >
        <imgix class="block-image" :src="image" :alt="alt"></imgix>
      </div>
      <div class="separated-header" :class="{ unsticky: !sticky }">
        <h2
          v-if="title && titleBig"
          class="block-title r-h4-to-h1"
          :class="{
            'text-pink': theme === 'pink',
            'text-brand': theme === 'blue',
          }"
        >
          {{ title }}
        </h2>
        <span
          v-if="title && !titleBig"
          class="block-title r-h4-to-h3"
          :class="{
            'text-pink': theme === 'pink',
            'text-brand': theme === 'blue',
          }"
        >
          {{ title }}
        </span>
        <router-link
          v-if="buttonLink"
          class="separated-header-button button primary hidden-mobile"
          :to="buttonLink"
        >
          {{ buttonText || "Get Started" }}

          <SVGIcon class="icon" name="arrow-right" width="20" height="20" />
        </router-link>
        <slot name="separated-left"></slot>
      </div>

      <div class="block-content">
        <imgix
          v-if="image"
          class="block-image hidden-mobile"
          :src="image"
          :alt="alt"
        ></imgix>

        <slot></slot>

        <router-link
          v-if="buttonLink"
          class="separated-bottom-button button primary hidden-desktop"
          :to="buttonLink"
        >
          {{ buttonText || "Get Started" }}

          <SVGIcon class="icon" name="arrow-right" width="20" height="20" />
        </router-link>

        <template v-if="spotTemplates.split('-').includes('1')">
          <Spot
            v-if="theme !== 'pink'"
            width="368"
            height="391"
            top="156"
            right="105"
            color="gold"
            blur="105"
            desktop
          />
          <Spot
            top="0"
            right="-240"
            z="-2"
            :color="theme === 'pink' ? 'magenta' : 'blue'"
            desktop
          />
        </template>
      </div>

      <template v-if="spotTemplates.split('-').includes('2')">
        <Spot
          width="222"
          height="208"
          top="0"
          right="16"
          color="magenta"
          blur="93"
          opacity="0.5"
          mobile
        />
        <Spot
          width="163"
          height="153"
          top="55"
          right="132"
          rotate="90"
          blur="75"
          opacity="0.5"
          z="-2"
          mobile
        />
      </template>

      <template v-if="spotTemplates.split('-').includes('3')">
        <Spot
          width="222"
          height="208"
          top="0"
          right="16"
          color="gold"
          blur="93"
          opacity="0.5"
          mobile
        />
        <Spot
          width="163"
          height="153"
          top="55"
          right="132"
          rotate="90"
          blur="75"
          opacity="0.5"
          z="-2"
          mobile
        />
      </template>
    </template>

    <template v-else>
      <div v-if="fullWidth" class="full-width-content">
        <slot></slot>
      </div>
      <slot v-else></slot>

      <template v-if="headerSpotsMobile">
        <Spot
          width="153"
          height="163"
          top="-110"
          right="135"
          blur="75"
          rotate="90"
          :color="headerSpotsMobile.split('-')[1]"
          mobile
        />
        <Spot
          width="222"
          height="208"
          top="-160"
          right="0"
          blur="92"
          z="-2"
          :color="headerSpotsMobile.split('-')[0]"
          mobile
        />
      </template>

      <template v-if="headerSpotsDesktop">
        <Spot
          width="391"
          height="367"
          top="-160"
          :right="105 - additionalGap"
          rotate="180"
          blur="105"
          :color="headerSpotsDesktop.split('-')[1]"
          mirror
          desktop
        />
        <Spot
          top="-160"
          :right="-216 - additionalGap"
          rotate="180"
          z="-2"
          :color="headerSpotsDesktop.split('-')[0]"
          mirror
          desktop
        />
      </template>
    </template>
  </section>
</template>

<style scoped src="./Block.scss" lang="scss" />
